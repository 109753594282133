import { makeDestinationOptions } from '@narrative.io/tackle-box/src/modules/app/destinations/destinationsModule'

let getSchemaPresets = async (openApi) => {
  const res = await openApi.get('/schema-presets')
  return Promise.resolve(res.data?.records)
}

let getAttributes = async (openApi) => {
  const res = await openApi.get('/attributes')
  return Promise.resolve(res.data?.records)
}

let getSchemaPreset = async (schemaPresetId, openApi) => {
  if (!schemaPresetId) {
    return Promise.resolve()
  } else {
    const res = await openApi.get(`/schema-presets/${schemaPresetId}`)
    return Promise.resolve(res.data)
  }
}

let getDatasets = async (openApi) => {
  const res = await openApi.get('/datasets')
  return Promise.resolve(res.data.records)
}

let getSellerCompanies = async (openApi) => {
  const res = await openApi.get('/company-info')
  return Promise.resolve(res.data.records)
}

let getDestinationOptions = async (token, baseURL, openApi) => {
  let reqHeaders = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  const destinationOptions = await makeDestinationOptions(baseURL, reqHeaders)
  return Promise.resolve(destinationOptions)
}

let getSubscriptions = async (openApi) => {
  const res = await openApi.get('/data-shops/subscriptions')
  return Promise.resolve(res.data.records.filter(subscription => subscription.status !== 'draft' && subscription.details?.data_rules?.attributes && subscription.details.type === 'marketplace').reverse())
}
let getProcessingRate = async (openApi) => {
  try {
    const res = await openApi.get('/contracts/active')
    return Promise.resolve(res.data.rates)
  } catch (error) {
    const mockContractRates = [{
      rate: {
        type: 'per_unit',
        unit_price: {
          value: 65.0,
          expressed_in: 'cents',
          'currency': 'USD'
        }
      },
      product: {
        id: 'default contract processing fee (no active contracts present)',
        sku: 'general-purpose-processing-aws-east-1',
        sku_type: {
          category: 'processing'
        },
        payable_company_id: null,
        unit: 'bytes',
        status: 'active'
      },
      start_at: null,
      end_at: null,
      created_at: null,
      updated_at: null
    },
    {
      rate: {
          type: 'percent_of_gmv',
          unit_price: {
              value: 25,
              expressed_in: 'percent',
              currency: 'USD'
          }
      },
      product: {
          id: 'default marketplace buyer fee (no active contracts present)',
          sku: 'marketplace-fee-buyer',
          sku_type: {
              category: 'marketplace-fee'
          },
          payable_company_id: 1,
          name: 'Marketplace Fee Buyer',
          unit: 'gmv',
          status: 'active'
      },
      start_at: '2023-03-01T00:00:00Z',
      end_at: null,
      created_at: '2023-04-07T20:38:30.182Z',
      updated_at: '2023-04-07T20:38:30.182Z'
  },
  {
      rate: {
          type: 'percent_of_gmv',
          unit_price: {
              value: 25,
              expressed_in: 'percent',
              currency: 'USD'
          }
      },
      product: {
          id: 'default marketplace seller fee (no active contracts present)',
          sku: 'marketplace-fee-seller',
          sku_type: {
              category: 'marketplace-fee'
          },
          payable_company_id: 1,
          name: 'Marketplace Fee Seller',
          unit: 'gmv',
          status: 'active'
      },
      start_at: '2023-03-01T00:00:00Z',
      end_at: null,
      created_at: '2023-04-07T20:38:30.182Z',
      updated_at: '2023-04-07T20:38:30.182Z'
  }]
    return Promise.resolve(mockContractRates)
  }
}
export {
  getSchemaPresets,
  getSchemaPreset,
  getAttributes,
  getDatasets,
  getSellerCompanies,
  getDestinationOptions,
  getSubscriptions,
  getProcessingRate
}